// FONTS
$font: 'Roboto', sans-serif;
$title: 'Raleway', sans-serif;
$icon: 'Material Icons';

// COLORS
$black: #222222;
$dark: #3B454E;
$grey: #aaaaaa;
$snow: #f5f5f5;
$red: #cc1f2d;
$blue: #1da1f2;
$green: #049809;
$yellow: #fff900;

$paypal: #1D3A85;
$facebook: #3b5998;
$twitter: #1da1f2;
$pinterest: #cb2027;
$youtube: #bb0000;
$instagram: #e95950;
$whatsapp: #2eb944;
$telegram: #2ba7dd;


// METRICS
$radius: 0.3rem;
$bigRadius: 0.8rem;

// GENERAL
@mixin module {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

// ANIMATION
@mixin trans {
  transition: all 0.15s ease;
}
@mixin hovers {
  &:hover, &:focus, &:active {
    @content;
  }
}
@mixin animate($name, $duration) {
  animation: $name $duration ease;
}

// MEDIA QUERIES
@mixin tv {
  @media screen and (min-width: 1600px) { @content; }
}
@mixin desktop {
  @media screen and (min-width: 1024px) { @content; }
}
@mixin tablet {
  @media screen and (min-width: 680px) { @content; }
}
@mixin notDesktop {
  @media screen and (max-width: 1023px) { @content; }
}
@mixin tabletOnly {
  @media screen and (min-width: 680px) and (max-width: 1024px) { @content; }
}
@mixin mobileOnly {
  @media screen and (max-width: 680px) { @content; }
}
@mixin mobileSmall {
  @media screen and (min-width: 0px) and (max-width: 320px) { @content; }
}
@mixin ie11 {
  @media all and (-ms-high-contrast:none) { @content; }
}
