@import "../styles";

.container {
  width: 100%;
  position: relative;
}

.quote {
  width: 100%;
  padding: 1rem;
  border-radius: 1rem;
  background-color: $snow;
  margin: 1.5rem 0;
  b {
    display: block;
    margin-bottom: 0.5rem;
  }
}