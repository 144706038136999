@import "./styles";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  color: $dark;
  font-size: 16px;
  font-family: 'Roboto', 'Helvetica Neue', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

a, button {
  cursor: pointer;
}

input {
  &:focus {
    border-color: $blue;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  margin-bottom: 1.5rem;
}

iframe {
  margin-bottom: 1.5rem;
}
