@import "../styles";

.container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.top {
  width: 100%;
  display: block;
  border-bottom: 1px solid $snow;
  background-color: white;
}

.header {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem;
  @include desktop {
    padding: 0.5rem 1.25rem;
    @include module;
  }
}
.button {
  display: inline-block;
  margin-right: 1rem;
}
.logo {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.75rem;
  color: $black;
  img {
    width: 100%;
    max-width: 30px;
    display: block;
    @include desktop {
      max-width: 40px;
    }
  }
  span {
    display: inline-block;
    margin-left: 0.75rem;
  }
}
.title {
  font-size: 1.15rem;
  font-weight: 300;
  text-transform: uppercase;
  @include tablet {
    font-size: 1.5rem;
  }
}

.content {
  width: 100%;
  display: block;
  min-height: 100vh;
  @include desktop {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    @include module;
    padding-top: 1rem;
  }
}

.page {
  width: 100%;
  padding: 6rem 1rem 1rem 1rem;
  // max-height: 100vh;
  // overflow-y: scroll;
  @include desktop {
    padding: 1.5rem 2.75rem 7rem 1.5rem;
    border-right: 1px solid $snow;
  }
}

.topSections {
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  border-bottom: 1px solid $snow;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  @include module;
  a {
    display: inline-block;
    padding: 0.5rem;
    color: $black;
    font-size: 0.8rem;
    &:hover {
      color: $blue;
      text-decoration: underline;
    }
    @include desktop {
      max-width: 30%;
    }
  }
}