@import "../styles";

.image {
  width: 100%;
  margin-bottom: 2rem;
  p {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  img {
    display: block;
    width: 100%;
  }
}

.title {
  font-size: 1.15rem;
  margin-bottom: 1rem;
}

.pdf {
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}
.pdfTitle {
  display: block;
  width: 100%;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.loadingGif {
  width: 100%;
  display: block;
  text-align: center;
  p {
    margin-bottom: 1rem;
  }
  img {
    width: 7rem;
    display: inline-block;
  }
}

.backToTop {
  width: 100%;
  text-align: center;
  button {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: $blue;
    margin: 1rem 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.pagination {
  width: 100%;
  display: block;
  text-align: center;
  border-top: 1px solid $snow;
  padding-top: 1rem;
  margin-top: 3rem;
}
.paginationContent {
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.paginationLink {
  display: inline-block;
  min-width: 20%;
  margin: 0 1rem;
  a {
    width: 100%;
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 0.9rem;
    color: white;
    background-color: $dark;
    border-radius: 0.75rem;
    text-transform: capitalize;
    @include trans;
    &:hover {
      background-color: $blue;
    }
  }
}
.paginationBack {
  width: 100%;
  margin: 2.5rem 0 0 0;
  a {
    padding: 0.5rem 3rem;
    font-size: 0.9rem;
    color: $black;
    background-color: $snow;
    border-radius: 0.75rem;
    &:hover {
      color: $blue;
    }
  }
}

.codeContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 9;
}

.codeContent {
  width: 100%;
  // max-width: 280px;
  display: inline-block;
  text-align: center;
  p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  label {
    display: block;
    margin-bottom: 1rem;
    input {
      padding: 0.5rem;
      background-color: $snow;
      border: 1px solid $grey;
      border-radius: $radius;
      text-align: center;
      &:focus {
        border: 1px solid $blue;
      }
    }
  }
  button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    color: white;
    background-color: $blue;
    border: 1px solid $blue;
    border-radius: $radius;
    &:disabled {
      border: 1px solid $grey;
      background-color: $grey;
      cursor: not-allowed;
    }
  }
}

.codeImage {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
