@import "../styles";

.container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content {
  margin-top: 3rem;
  display: inline-block;
  b {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
  a {
    color: $blue;
    font-size: 0.9rem;
    &:hover {
      text-decoration: underline;
    }
  }
}