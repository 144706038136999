@import "../styles";

.container {
  width: 100%;
  line-height: 1.6;
  font-size: 1rem;
  text-align: justify;
  p {
    display: block;
    margin-bottom: 1.25rem;
    a {
      color: $blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.quote {
  width: 100%;
  padding: 1rem;
  border-radius: 1rem;
  background-color: $snow;
  margin: 1.5rem 0;
  b {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.list {
  font-size: 0.95rem;
  list-style: none;
  li {
    margin-bottom: 0.25rem;
    a {
      color: $dark;
      &:hover {
        color: $blue;
        text-decoration: underline;
      }
    }
  }
}

.underline {
  text-decoration: underline;
}

.right {
  width: 100%;
  margin: 1rem 0;
  text-align: right;
  p {
    margin-bottom: 0rem;
  }
}
.end {
  width: 100%;
  margin: 1.5rem 0;
  text-align: right;
  p {
    margin-bottom: 0rem;
  }
}
.blue {
  color: $blue;
}