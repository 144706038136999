@import "../styles";

.container {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 4.55rem;
  left: 0;
  z-index: 1;
  background-color: white;
  transform: translateX(-100%);
  overflow-y: scroll;
  @include trans;
  @include desktop {
    width: 26rem;
    max-height: 100vh;
    padding-bottom: 7rem;
    border-right: 1px solid $snow;
    position: sticky;
    top: 0;
    transform: translateX(0);
    overflow-y: scroll;
  }
}
.containerOpen {
  transform: translateX(0);
}
.content {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 2rem 1rem 1rem;

  p {
    font-size: 0.9rem;
    display: inline-block;
    padding: 0.55rem;
    color: $grey;
  }

  a {
    font-size: 0.9rem;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.55rem;
    color: $dark;
    &:first-of-type {
      font-size: 1.1rem;
    }
    @include hovers {
      color: $blue;
    }
    &.activeLink {
      color: $blue;
    }
    span {
      display: inline-block;
    }
    span:first-of-type {
      min-width: 20px;
    }
    span:last-of-type {
      margin-left: 0.5rem;
    }
  }

}